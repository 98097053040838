/* src/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  width: 100%; /* Use viewport width to ensure full width */
  height: 100%; /* Ensure it takes full viewport height */
  max-width: 100vw; /* Prevent any overflow beyond the viewport */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  @apply bg-gray-50;
}

/* In some cases, the default CSS margins and paddings applied by 
 * browsers can cause issues. Use a CSS reset at the beginning of your styles 
 * to ensure consistency across browsers. 
 *
 * By addressing these areas, you should be able to stop your site from 
 * scrolling down automatically when opened on a mobile device.
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container {
  @apply w-full max-w-full h-full;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.body-container {
  @apply space-y-5 mx-8;
}

/* Fonts */
.cursive-font {
  font-family: "Great Vibes", cursive;
}

.oswald-font {
  font-family: "Oswald", sans-serif;
}

.tangerine-font {
  font-family: "Tangerine", serif;
}

.playfair-display-font {
  font-family: "Playfair Display", serif;
}

.montserrat-font {
  font-family: "Montserrat", sans-serif;
}

/* Signature (Top) */
.signature {
  @apply w-full;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  @apply p-2;
  @apply space-y-2;
  /* background-color: rgba(255, 105, 180, 0.8); /* Light Pink with Transparency */
  /* @apply sm:rounded-bl-3xl sm:rounded-br-3xl md:rounded-bl-full md:rounded-br-full lg:rounded-bl-full lg:rounded-br-full; */
  @apply z-10; /* Ensure content is above the background */

  @apply text-slate-950;
  @apply bg-gray-50;
  text-align: center;
}

.signature img {
  @apply w-full;
  @apply sm:h-auto md:h-auto lg:h-auto;
  max-height: 85vh;
}

.signature h1 {
  @apply text-8xl font-bold cursive-font pt-8 pb-2;
}

.signature h2 {
  color: #d9534f;
  @apply text-5xl playfair-display-font uppercase tracking-widest;
}

.signature h3 {
  @apply text-2xl montserrat-font pt-2 uppercase tracking-wider;
}

.signature-img-left {
  @apply hidden;
  @apply xl:absolute xl:left-0 xl:top-2;
  @apply xl:block; /* Optional: Ensures image doesn't have inline spacing */
  @apply w-96 h-52;
}

.signature-img-right {
  @apply hidden;
  @apply xl:absolute xl:right-20 xl:-top-20;
  transform: rotate(90deg); /* Rotates the image 90 degrees */
  @apply xl:block; /* Optional: Ensures image doesn't have inline spacing */
  @apply w-52 h-96;
}

/* Header */
.header {
  @apply w-full;
  @apply sticky top-0 z-50;
  @apply bg-gray-50 opacity-95;
  @apply content-center pt-1;
}

.header-nav {
  @apply flex flex-wrap;
  align-items: center;
}

.header-logo {
  font-size: 1.5rem;
  font-weight: bold;
  @apply pt-2;
  @apply cursive-font;
  letter-spacing: 3px;
  @apply whitespace-nowrap;
  display: flex;
  align-items: center; /* Vertically center the heart with the text */
}

.heart-symbol {
  margin-left: 0.5rem; /* Add space between text and heart */
  font-size: 1.5rem; /* Adjust size to match header text */
}

.header-menu {
  display: flex;
  gap: 1rem;
  list-style: none;
}

.header-button {
  @apply text-gray-950;
  cursor: pointer;
  @apply p-1 w-32;
  @apply border-b-2 border-solid border-transparent; /* Default transparent border */
  @apply tangerine-font text-3xl tracking-wider;
  @apply whitespace-nowrap;
}

.header-button:hover {
  @apply border-b-red-400;
  @apply border-b border-solid;
  border-radius: 5px;
}

.header-button-selected {
  @apply border-b-red-500;
  @apply border-b border-solid;
  border-radius: 5px;
}

/* Hero */
.hero {
  @apply flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.hero img {
  @apply w-full;
  @apply rounded-3xl;
}

/* Section */
.section {
  @apply p-3;
  @apply text-center;
  @apply rounded-2xl shadow-md;
  @apply playfair-display-font;
  @apply scroll-mt-14;
  @apply z-50;
  @apply bg-gray-50;
}

.section h2 {
  font-size: 2.5em;
}

.section-white {
  @apply section;
}

.section-pink {
  @apply section;
  @apply border-4 border-pink-100;
}

.section-pink h2 {
  color: #d9534f;
}

/* About */

/* Gallery */
.gallery-images {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.gallery-images img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  @apply rounded-xl shadow-2xl;
}

/* Venue */
.venue-displays {
  @apply flex flex-col gap-3 w-full h-2/3;
  @apply sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.venue-details {
}

.venue-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.venue-address {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.venue-description {
  font-size: 1em;
  color: #6c757d;
  margin-bottom: 30px;
}

.venue-map {
  @apply sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.venue-map iframe {
  width: 100%;
  max-width: 600px;
  height: 450px;
  border: 0;
  border-radius: 10px;
}

/* Hotels */
.hotels-list {
  @apply w-full;
  display: flex; /* Use flexbox to align items horizontally */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  justify-content: center; /* Center items */
}

.hotel-item {
  @apply flex flex-col gap-4;
  @apply w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2;
  @apply bg-pink-50 bg-opacity-50;
  @apply border border-gray-400;
  border-radius: 8px;
  @apply py-6;
  @apply px-4 sm:px-4 md:px-20 lg:px-20 xl:px-20 2xl:px-20;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hotel-item img {
  @apply w-full h-auto;
  @apply place-self-center;
}

.hotel-name {
  font-size: 1.5em;
  font-weight: bold;
}

.hotel-address {
  font-size: 1em;
  color: #555;
}

.hotel-phone {
  @apply text-blue-500 underline underline-offset-4 whitespace-nowrap;
}

.hotel-phone:hover {
  @apply underline-offset-8;
  transition: text-underline-offset 0.3s ease, color 0.3s ease;
}

.hotel-link {
  color: #d9534f;
  @apply underline underline-offset-4 whitespace-nowrap;
}

.hotel-link:hover {
  @apply underline-offset-8;
  transition: text-underline-offset 0.3s ease, color 0.3s ease;
}

.hotel-block-button {
  @apply h-10 w-min self-center;
  @apply px-2;
  @apply tracking-wide;
  @apply whitespace-nowrap;
  @apply bg-blue-500 hover:bg-blue-400 text-gray-50 rounded;
}

/* Registry */
.registry-images {
  display: flex; /* Use flexbox to align items horizontally */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center align items */
}

.registry-images img {
  @apply bg-white;
  @apply p-4;
  @apply w-min h-min;
  @apply object-fill;
  @apply rounded-xl shadow-2xl;
  @apply cursor-pointer;
  @apply border border-gray-400;
}

/* FAQ */
.faqs-list {
  display: flex; /* Use flexbox to align items horizontally */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center align items */
}

.faq-item {
  @apply bg-pink-200 bg-opacity-75;
  @apply border border-gray-400;
  border-radius: 8px;
  padding: 20px;
  margin: 10px; /* Margin between items */
  width: calc(
    50% - 20px
  ); /* Each item takes up half the width, accounting for margin */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  @apply min-w-60;
}

.faq-question {
  font-size: 1.2em;
  font-weight: bold;
}

.faq-answer {
  font-size: 1em;
  color: #555;
}

/* Stream */

/* RSVP */
.rsvp-form {
  @apply gap-3;
  display: flex;
  flex-direction: column;
  @apply justify-center items-center;
}

.rsvp-form input,
.rsvp-form textarea,
.rsvp-form button {
  @apply self-center;
  @apply h-9;
  @apply px-2;
  border: 1px solid #ddd;
  border-radius: 4px;
  @apply border-gray-500;
}

.rsvp-form textarea {
  resize: vertical;
}

.checkbox-container {
  @apply justify-center;
  display: flex;
  align-items: center;
  @apply h-full;
  @apply mb-4;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  @apply text-xl;
  white-space: nowrap;
  @apply font-semibold tracking-wider underline underline-offset-4;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: 2px solid #000; /* Border around the checkbox */
}

.checkmark {
  display: inline-block;
  width: 25px; /* Adjust the size as needed */
  height: 27px; /* Adjust the size as needed */
  @apply bg-gray-100 border border-gray-300;
  border-radius: 5px;
  @apply border-black;
  margin-right: 10px; /* Adjust the spacing as needed */
  position: relative;
}

.custom-checkbox:checked + .checkmark {
  @apply bg-green-500;
  @apply opacity-60;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox:checked + .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.rsvp-input {
  @apply flex flex-wrap;
  @apply gap-3;
  @apply justify-center items-center;
}

.rsvp-input-section {
}

.rsvp-input-section input:disabled {
  @apply cursor-not-allowed;
}

.attending-choice {
  @apply flex flex-row gap-2;
}

.attending-choice legend {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.attending-choice-inputs {
  @apply flex flex-wrap gap-2 justify-center;
}

/* Attending Choice Radio */
.attending-choice-radio-option {
  @apply font-bold;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  @apply align-middle;
}

.attending-choice-radio-option input[type="radio"] {
  display: none; /* Hide the default radio button */
}

/* The custom radio button circle */
.radio-btn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid;
  @apply border-blue-500;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  transition: border-color 0.3s ease;
  @apply cursor-pointer;
}

/* When the radio button is selected, fill it with green */
.attending-choice-radio-option input[type="radio"]:checked + .radio-btn {
  border-color: #4caf50; /* Change border color to green */
  background-color: #4caf50; /* Fill with green */
}

.radio-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.attending-choice-radio-option input[type="radio"]:disabled + .radio-btn {
  @apply bg-gray-400;
  @apply cursor-not-allowed;
}

.attending-choice-radio-option input[type="radio"]:checked + .radio-btn::after {
  opacity: 1; /* Show white circle when selected */
}

/* Optional: Hover effects for smooth interaction */
.attending-choice-radio-option:hover .radio-btn {
  border-color: #aaa;
}

.dinner-choice {
}

.dinner-choice legend {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dinner-choice-inputs {
  @apply flex flex-wrap gap-2 justify-center;
}

.dinner-choice-radio-option {
}

.dinner-choice-radio-option label {
  @apply w-24 h-9;
  @apply pt-1.5;
  @apply text-center align-middle;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  @apply border-black;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s, border-color 0.3s;
}

.dinner-choice-radio-option label:hover {
  background-color: #ffebeb;
  border-color: #ff6666;
}

.dinner-choice input[type="radio"] {
  display: none;
}

.dinner-choice input[type="radio"]:checked + label {
  background-color: #ff6666;
  color: #fff;
  border-color: #ff6666;
}

.dinner-choice input[type="radio"]:disabled + label {
  @apply bg-gray-200;
  border: 2px solid #ccc;
  @apply cursor-not-allowed;
}

.rsvp-email-address {
  @apply sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 text-center whitespace-nowrap;
}

.rsvp-email-address legend {
  @apply font-semibold;
}

.rsvp-email-address input {
  @apply w-full;
}

.rsvp-email-address input:disabled {
  @apply cursor-not-allowed;
}

.rsvp-submit-button {
  @apply w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3;
  @apply justify-center bg-green-300 disabled:cursor-not-allowed;
}

/* Footer */
.footer {
  background-color: #d32f2f; /* Red-600 */
  color: #ffffff;
  @apply mt-3;
  padding: 1rem;
  text-align: center;
  @apply playfair-display-font;
}

/* Password Screen */
.password-prompt {
  @apply w-full h-full;
  @apply p-2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #ffe0e9, #ffffff);
  font-family: "Georgia", serif;
}

.password-prompt-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.password-prompt-container h1 {
  @apply text-8xl font-bold cursive-font pt-8;
}

.password-prompt-container h2 {
  @apply text-5xl playfair-display-font tracking-wider pb-2;
}

.password-prompt-container h3 {
  color: #d9534f;
  @apply text-5xl font-bold cursive-font tracking-wider;
  @apply pt-4;
}

.password-prompt-container h4 {
  margin-bottom: 10px;
  color: #d9534f;
  @apply text-4xl;
}

.password-prompt-container h5 {
  @apply text-xl;
}

.password-prompt-container h6 {
  margin-bottom: 5px;
}

.password-prompt-container p {
  margin-bottom: 10px;
  color: #6c757d;
}

.password-prompt-container input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.password-prompt-container input:focus {
  outline: none;
  border-color: #d9534f;
}

.password-prompt-container button {
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1em;
}

.password-prompt-container button:hover {
  background-color: #c9302c;
}

.password-prompt-carousel {
  max-width: 500px;
}

.error-message {
  color: #d9534f;
  margin-top: 10px;
}

/* Carousel */
.carousel {
  height: 400px;
  @apply rounded-2xl shadow-lg;
  @apply z-30;
}

.carousel img {
  @apply w-full h-full;
  @apply object-cover rounded-2xl z-50;
}

/* Close Button */
.close-button {
  @apply h-10 w-10;
  @apply mt-6;
  @apply bg-red-500 text-gray-50 rounded hover:bg-red-400;
}

.close-button:disabled {
  @apply bg-gray-400;
  @apply cursor-not-allowed;
}

/* Add Button */
.add-button {
  @apply mt-2;
  @apply bg-yellow-500;
  @apply text-gray-950 px-5 py-1 rounded;
  @apply hover:bg-yellow-400;
}

/* Modal */
.close-modal-button {
  @apply p-2;
  @apply bg-red-500 text-gray-50 rounded hover:bg-red-400;
}

/* Spinner */
.spinner {
  @apply mt-1;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Dinner Menu */
.dinner-menu {
  @apply mb-4;
  @apply flex flex-col gap-1;
  @apply place-self-center;
  @apply border rounded-2xl border-blue-800;
}

.dinner-menu h1 {
  @apply text-xl font-bold;
}

.dinner-menu-table-option {
  @apply text-left;
  @apply max-w-min;
}
.dinner-menu-table-description {
  @apply text-left;
  @apply w-full;
}
